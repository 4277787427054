section#contact {
  width: 100vw;
}
div.contact__content--projects {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
  gap: calc(3rem + 0.52083333333vw);
}
div.section__content1 {
  height: 100vh;
  width: 100vw;
}

div.contact__content--projects--details {
  width: calc(10.41666666667vw + 13.4259259259vh);
  height: calc(10.41666666667vw + 13.4259259259vh);
  justify-content: center;
  background-color: #202221;
  color: var(--primary-color);
  padding: 0 calc(2rem + 0.67708333333vw);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div.contact__content--projects--details__img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(6.25vw + 5.3703703704vh);
  border-bottom: solid 0.1rem rgba(120, 193, 147, 0.1);
}

div.contact__content--projects--details__img p {
  line-height: 2.4rem;
  font-size: calc(1rem + 0.3125vw);
  font-weight: 700;
}

div.contact__content--projects--details__text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: calc(1rem + 00.416666667vw);
  line-height: calc(1.2rem + 0.78125vw);
  margin-top: 14px;
}

div.contact__content--projects--details__text a {
  color: var(--primary-color);
  text-decoration: none;
}

@media screen and (max-width: 1024px) {
  div.contact__content--projects {
    flex-direction: column;
    align-items: center;
    margin: 0 auto 4rem;
  }
  div.section__content1 {
    height: 100vh;
    width: 100vw;
    padding: 24px 0px 16px;
  }

  div.contact__content--projects--details {
    width: calc(40.46666667vw);
    height: calc(42vw);
    margin-right: 0;
    margin-bottom: calc(0.8rem + 3.2vw);
  }

  div.contact__content--projects--details__img {
    height: 50%;
    /* width: 100%; */
  }
  div.contact__content--projects--details__text {
    margin-top: 10%;
  }
}

@media screen and (max-width: 600px) {
  div.contact__content--projects--details {
    width: calc(91.46666667vw);
    height: calc(92vw);
    padding: 0 calc(2rem + 3.2vw);
  }

  div.contact__content--projects--details__img {
    height: 60%;
  }
  div.contact__content--projects--details__text,
  div.contact__content--projects--details__img p {
    font-size: calc(1rem + 2.133333333vw);
    line-height: calc(1.2rem + 4vw);
  }

  div.contact__content--projects--details__img p {
    font-weight: 700;
  }
}
