section#about-me {
  width: 100vw;
}

div.about-me__content {
  height: calc(100vh - 51px);
  width: 100%;
  padding-top: calc(2rem + 0.83333333333vw);
  padding-left: calc(15rem + 3.697916667vw);
}

h2.section-header {
  display: inline-block;
  margin-bottom: calc(2rem + 0.67708333333vw);
  font-size: calc(3rem + 0.9375vw);
  line-height: calc(3.5rem + 1.42708333333vw);
  font-family: "Horizontal";
  color: var(--primary-color);
  padding: 0 0.7422rem;
}

div.about-me__content--description {
  width: 90%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  justify-content: center;
  gap: 0 5rem;
  margin: 0 auto;
  align-items: center;
}

div.about-me__content--description__img {
  text-align: center;
}
div.about-me__content--description__img img {
  max-width: 100%;
  height: auto;
}

div.about-me__content--description__para {
  font-size: calc(0.5rem + 0.68421052632vw);
  line-height: calc(1.5rem + 0.625vw);
  gap: 2rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
div.about-me__content--description__para h2 {
  color: var(--primary-color);
  text-transform: uppercase;
  margin-bottom: 2rem;
  font-size: calc(2.325rem + 0.9vw);
  font-weight: 700;
}
div.about-me__content--description__para p {
  line-height: 150%;
}

@media screen and (max-width: 1024px) {
  div.about-me__content {
    padding-left: 0;
    height: 100%;
  }

  div.about-me__content--description {
    grid-template-columns: 1fr;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0;
    gap: 0;
  }

  div.about-me__content--description__para {
    width: 100%;
    font-size: calc(0.8rem + 0.68421052632vw);
    line-height: calc(1.8rem + 0.625vw);
    color: #fff;
    margin-top: calc(2.604166667vw + 2.962962963vh);
    justify-content: flex-start;
    grid-area: 2 / span 2;
  }

  div.about-me__content--description__img img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 600px) {
  div.about-me__content {
    padding-left: 0;
  }

  div.about-me__content--description {
    width: 100%;
  }

  div.about-me__content--description__para {
    padding: 3.2rem 1.6rem;
    margin: 0;
    font-size: 14px;
  }

  div.about-me__content--description__para p {
    margin-bottom: calc(1.5rem + 0.9765625vw);
  }

  div.about-me__content--description__img {
    max-width: 100%;
    padding: 2.4rem 1.6rem 0;
  }
}
