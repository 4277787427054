div.home__content--projects__images {
	display: flex;
	/* height: 303px; */
	margin-top: 2rem;
	gap: 4rem;
	width: 100%;
}

div.home__content--projects__images .scroll-horizontal > div {
	display: flex;
	flex-direction: column;
	grid-gap: 10rem;
	flex-wrap: wrap;
	gap: 10rem;
	width: 400px;
	height: 231px;
}

@media screen and (max-width: 1650px) {
	div.home__content--projects__images {
		margin-top: 1rem;
		gap: 0 3.5rem;
	}

	div.home__content--projects__images .scroll-horizontal > div {
		display: flex;
		flex-direction: column;
		grid-gap: 10rem;
		flex-wrap: wrap;
		gap: 10rem;
		width: 400px;
		height: 231px;
	}
}

@media screen and (max-width: 1024px) {
	div.home__content--projects__images {
		flex-direction: column;
		width: 100%;
	}
}
@media screen and (max-width: 768px) {
	div.home__content--projects__images {
		display: flex;
		flex-direction: column;
		margin: 0;
	}

	div.home__content--projects__images {
		gap: 2rem;
	}
}
