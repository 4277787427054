/* ------------- Side Nav ------------- */
.overflow-hidden {
  overflow: hidden;
  position: fixed;
}

aside#side-nav {
  /* position: sticky; */
  z-index: 1;
  float: left;
  top: 0;
  left: 0;
  height: 100vh;
  border-right: solid 0.1rem var(--secondary-color-m);
}



div.side-nav__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--secondary-color);
  backdrop-filter: blur(80px);
}

div.side-nav__content--logo {
  padding: 0 4.4rem;
}

div.side-nav__content--logo img {
  max-width: 86px;
  margin: 3rem auto;
  object-fit: cover;
}

ul.side-nav__content--links {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  gap: 5rem 0;
  position: absolute;
  width: 100%;
}

.dropdown-menu {
  background-color: rgb(33, 35, 34, 1);
  transform: translate(111px, -21px) !important;
  text-align: center;
  min-width: 18rem;
}

.dropdown-menu li {
  padding: 0.3rem 0;
  margin: 2rem 0;
}

.dropdown-item.active {
  color: var(--primary-color);
  background: none;
}

.dropdown-item.active::after {
  width: 160px !important;
}


ul.side-nav__content--links a,
ul .dropdown {
  color: var(--primary-color);
  line-height: 2.4rem;
  font-size: calc(1rem + 0.3125vw);
  font-weight: 700;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
}

ul.side-nav__content--links a.active:link::after,
ul.side-nav__content--links a.active:active:after {
  content: "";
  position: absolute;
  width: 150%;
  height: 0.2rem;
  background-color: var(--primary-color);
  top: 45%;
  left: 50%;
  opacity: 0.2;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1600px) {
  div.side-nav__content--logo img {
    max-width: 60px;
  }
}

@media screen and (max-width: 1024px) {

  /* ------------- Side Nav ------------- */
  aside#side-nav {
    float: none;
    height: auto;
    width: 100vw;
    position: relative;
    border-right: none;
    border-bottom: solid 0.1rem var(--secondary-color-m);
  }

  div.side-nav__content {
    flex-direction: row;
  }

  div.side-nav__content--logo {
    padding: 0 3.2rem;
  }

  div.side-nav__content--logo img {
    max-width: 24px;
    margin: 1rem auto;
  }

  ul.side-nav__content--links {
    position: fixed;
    width: 100vw;
    height: 100vh;
    /* background-color: var(--secondary-color); */
    display: none;
    background: rgba(33, 35, 34, 0.85);
    backdrop-filter: blur(80px);
  }

  ul.side-nav__content--displayed-links {
    display: flex;
    justify-content: flex-start;
  }

  ul.side-nav__content--displayed-links .first-link {
    margin-top: calc(6rem + 1.5625vw);
  }


  ul a {
    z-index: 999;
  }

  .dropdown-menu {
    transform: translate(-36%, 35px) !important;
  }

  div.side-nav__content--hamburger {
    height: calc(2.1rem + 0.3645833333vw);
    width: calc(2.1rem + 0.3645833333vw);
    position: fixed;
    top: 1rem;
    right: 1.6rem;
    padding: 0.2rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  div.side-nav__content--hamburger .line {
    width: 100%;
    height: 0.2rem;
    border-radius: 10%;
    background-color: var(--primary-color);
    transition: all 0.4s ease-in-out;
  }

  #side-nav.faded .side-nav__content--logo {
    opacity: 0;
  }

  #side-nav.faded {
    border: 0;
  }

  #side-nav.faded .side-nav__content {
    background-color: var(--tertiary-color);
  }

  section.faded {
    opacity: 0;
  }

  .hamburger-line-move .line1 {
    transform: rotateZ(-405deg) translate(-0.7rem, 0.6rem);
  }

  .hamburger-line-move .line2 {
    opacity: 0;
  }

  .hamburger-line-move .line3 {
    transform: rotateZ(405deg) translate(-0.2rem, -0.2rem);
  }

  /* ------------- Side Nav ------------- */
}

@media only screen and (max-width: 600px) {
  ul.side-nav__content--displayed-links {
    gap: 4rem;
  }

  aside#side-nav {
    z-index: 1000;
  }

  div.side-nav__content--logo {
    padding: 0 1.6rem;
  }
}