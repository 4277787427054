/* Modal for Home */
div.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: flex-start;
	background-color: var(--secondary-color);
	backdrop-filter: blur(80px);
	display: none;
}

div.modal__content {
	margin: 18.6111111111vh 3.125vw 0 4.166666667vw;
	height: 62.87037037vh;
	width: 63.020833333333vw;
}

div.modal__content img {
	height: 62.87037037vh;
	width: 63.020833333333vw;
	object-fit: cover;
}

div.modal__description {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-top: 19.07407407vh;
}

div.modal__description--heading {
	width: 27.1vw;
	/* height: 15.92592593vh; */
	color: var(--primary-color);
	border-bottom: solid 0.1rem rgba(120, 193, 147, 0.5);
	margin-bottom: 2.0833333333333333vw;
}

div.modal__description--heading h3 {
	font-size: 2.08333333333vw;
	line-height: 2.7083333333vw;
	display: inline-block;
	font-weight: 700;
	width: 21.875vw;
	margin-bottom: 1.0416666667vw;
}

div.modal__description--heading h4 {
	width: 5vw;
	height: 2.5vh;
	font-size: calc(1rem + 0.416666667vw);
	line-height: calc(1.5rem + 0.625vw);
	margin-bottom: 1.0416666667vw;
}

div.modal__description--para {
	color: #fff;
	font-size: calc(1rem + 0.416666667vw);
	line-height: calc(1.5rem + 0.625vw);
	width: 26.45833333333vw;
	height: 20.65625vw;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	overflow-x: hidden;
	overflow-y: auto;
	border-radius: 2%;
}
.modal__description--para::-webkit-scrollbar {
	width: 0.5em;
	height: 0.5em;
}
.modal__description--para::-webkit-scrollbar-button {
	background: #ccc;
	height: 0;
}
.modal__description--para::-webkit-scrollbar-track-piece {
	background: #888;
}
.modal__description--para::-webkit-scrollbar-thumb {
	background: #eee;
}
​ div.modal__description--para__member {
	padding: 10px;
}

div.modal__close-btn {
	position: fixed;
	top: 3.833rem;
	right: 3.833rem;
	width: 2.333rem;
	height: 2.333rem;
	cursor: pointer;
	/* display: flex;
      flex-direction: column;
      justify-content: space-between; */
}

div.close-btn--line {
	background-color: var(--primary-color);
	width: 100%;
	height: 0.2rem;
	position: absolute;
	top: 50%;
}

div.close-btn--line1 {
	transform: rotate(45deg);
}

div.close-btn--line2 {
	transform: rotate(-45deg);
}

/* toggle modal */
div.display-modal {
	display: flex;
}
/* toggle modal */

/* Modal for Home */
@media only screen and (max-width: 1400px) {
	div.modal__description--para {
		height: 18.65625vw;
	}
}

@media screen and (max-width: 1024px) {
	div.modal__description--para {
		width: 41vw;
	}

	div.modal {
		flex-direction: column;
	}

	section#home {
		overflow: scroll;
	}

	div.modal__close-btn {
		top: 2.1rem;
		right: 3rem;
		width: 2rem;
		height: 2rem;
	}

	div.modal__content {
		width: 100vw;
		height: 42vh;
		margin: 5.6rem auto 0;
	}

	div.modal__content img {
		height: 40vh;
		width: 100vw;
		object-fit: cover;
	}

	div.modal__description {
		width: 90vw;
		margin: 3.2rem auto;
	}

	div.modal__description--heading {
		width: calc(30rem + 11.4666666667vw);
		margin-bottom: calc(1rem + 2.666666667vw);
	}

	div.modal__description--heading h3 {
		width: calc(24rem + 8.533333333vw);
		font-size: calc(1.5rem + 1.6vw);
		line-height: calc(2.34rem + 2.08vw);
		/* margin-bottom: calc(0.8rem + 2.666666667vw); */
	}
}

@media screen and (max-width: 600px) {
	div.modal {
		flex-direction: column;
	}

	div.modal__description--para {
		overflow: unset;
		width: 100%;
	}

	div.modal__close-btn {
		top: 2.1rem;
		right: 2.1rem;
		width: 1.4rem;
		height: 1.4rem;
	}

	div.modal__content {
		width: 100vw;
		height: 55.7333333333vw;
		margin: 5.6rem 0 0;
	}

	div.modal__content img {
		height: 55.7333333333vw;
		width: 100vw;
		object-fit: cover;
	}

	div.modal__description {
		width: 100vw;
		margin: 3.2rem 1.6rem;
	}

	div.modal__description--para {
		font-size: 1.4rem;
	}

	div.modal__description--heading {
		width: calc(30rem + 11.4666666667vw);
		margin-bottom: calc(1rem + 2.666666667vw);
	}

	div.modal__description--heading h3 {
		width: 100%;
		font-size: calc(1.8rem + 1.6vw);
		line-height: calc(2.34rem + 2.08vw);
		margin-bottom: 1.6rem;
		height: auto;
	}

	div.modal__description--heading h4 {
		width: auto;
		height: auto;
	}

	/* Modal */
}
