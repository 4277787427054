div.home__content--projects__images .home__content--projects__image--wrapper {
  max-width: 410px;
  line-height: 0;
  border: 1px solid #1f2421;
  border-radius: 3%;
}

div.home__content--projects__images
  .home__content--projects__image--wrapper
  img {
  width: 410px;
  height: 231px;
  object-fit: cover;
  padding: 20px 20px;
}

.home__descript--commercial {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  line-height: 22px;
}

.home__descript--commercial > a {
  color: rgb(120, 193, 147);
  font-size: 20px;
  font-weight: 700;
  overflow: hidden;
}
.home__descript--commercial > p {
  color: #ffff;
  font-size: 16px;
  overflow: hidden;
  margin-top: 1.5rem;
}
.home__descript--commercial > a:hover {
  color: rgba(120, 193, 147, 0.8);
}

@media screen and (max-width: 1650px) {
  div.home__content--projects__images
    .home__content--projects__image--wrapper
    img {
    width: 370px;
    height: 190px;
    padding: 15px;
  }

  .home__descript--commercial {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding: 0 15px 15px;
    line-height: 22px;
  }

  .home__descript--commercial > a {
    font-size: 16px;
  }
  .home__descript--commercial > p {
    font-size: 14px;
    margin-top: 1.2rem;
  }
  .home__descript--commercial > a:hover {
    color: rgba(120, 193, 147, 0.8);
  }
}

@media screen and (max-width: 1024px) {
  div.workitem--listscroll {
    width: 100%;
  }
  .workitem--listscroll img {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {

  .home__descript--commercial {
    padding: 0;
    line-height: 22px;
  }
}
