/* ------------- Home ------------- */
body{
  overflow: hidden !important; 
}
.home__title {
  color: rgb(126, 201, 153);
  text-transform: uppercase;
  font-weight: 700;
  line-height: 23px;
  font-size: 42px;
}

.home__video-wrapper {
  width: calc(100vw - 174px);
  position: relative;
}

.home__slide-below-wrapper {
  display: flex;
}

.home__slide-below {
  margin-right: 4rem;
}

.preview-background-overlay {
  position: absolute;
  background: black;
  opacity: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.slide-background-overlay {
  position: absolute;
  opacity: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
}

div.home__video-thumbnail {
  flex: 1 0 100%;
}

div.home__responsive-mobile-ver {
  display: flex;
  flex-direction: row;
}

section#home {
  width: 100vw;
  height: 100vh;
}

.home__image-preview {
  margin-left: 3.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

div.section__content {
  height: 100vh;
  width: 100vw;
}

h2.section-header {
  display: inline-block;
  margin-bottom: calc(2rem + 0.67708333333vw);
  font-size: calc(3rem + 0.9375vw);
  line-height: calc(3.5rem + 1.42708333333vw);
  font-family: "Horizontal";
  color: var(--primary-color);
  padding: 0 0.7422rem;
}

div.home__content--projects {
  gap: 1rem;
  overflow-x: scroll;
  height: 100vh;
}

.hide_scrollbar::-webkit-scrollbar {
  display: none;
}

body::-webkit-scrollbar {
  display: none;
}

div.home__line {
  flex: 0 0 auto;
}

.home__image-preview .-shortad {
  width: 98.8%;
}

.home__image-preview .-fb {
  width: 97%;
}

.home__image-preview .-doc {
  width: 90.6%;
}

.home__image-preview .-fm {
  width: 86.6%;
}

.home__image-preview .-cy {
  width: 95.666667%;
}

.home__image-preview .-tch {
  width: 93%;
}

.home__image-preview .-mv {
  width: 88.9%;
}

div.home__line::after {
  content: "";
  background-color: #2a312d;
  width: 100%;
  height: 2px;
  position: absolute;
  top: 0%;
  bottom: 0%;
  margin: auto;
}

/* Modal for Home */
div.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  background-color: var(--secondary-color);
  backdrop-filter: blur(80px);
  display: none;
}

div.modal__content {
  margin: 18.6111111111vh 3.125vw 0 4.166666667vw;
  height: 62.87037037vh;
  width: 63.020833333333vw;
}

div.modal__content img {
  height: 62.87037037vh;
  width: 63.020833333333vw;
  object-fit: cover;
}

div.modal__description {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 19.07407407vh;
}

div.modal__description--heading {
  width: 27.1vw;
  color: var(--primary-color);
  border-bottom: solid 0.1rem rgba(120, 193, 147, 0.5);
  margin-bottom: 2.0833333333333333vw;
  padding-bottom: 1.5rem;
}

div.modal__description--heading h3 {
  font-size: 2.08333333333vw;
  line-height: 2.7083333333vw;
  display: inline-block;
  font-weight: 700;
  margin-bottom: 1.0416666667vw;
}

div.modal__description--heading h4 {
  width: 5vw;
  height: 2.5vh;
  font-size: calc(1rem + 0.416666667vw);
  line-height: calc(1.5rem + 0.625vw);
  margin-bottom: 1.0416666667vw;
}

div.modal__description--para {
  color: #fff;
  font-size: calc(1rem + 0.416666667vw);
  line-height: calc(1.5rem + 0.625vw);
  width: 26.45833333333vw;
  height: 12.65625vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

div.modal__close-btn {
  position: fixed;
  top: 3.833rem;
  right: 3.833rem;
  width: 2.333rem;
  height: 2.333rem;
  cursor: pointer;
}

div.close-btn--line {
  background-color: var(--primary-color);
  width: 100%;
  height: 0.2rem;
  position: absolute;
  top: 50%;
}

div.close-btn--line1 {
  transform: rotate(45deg);
}

div.close-btn--line2 {
  transform: rotate(-45deg);
}

/* toggle modal */
div.display-modal {
  display: flex;
}

/* toggle modal */

/* Modal for Home */

.home__indicator-background {
  position: absolute;
  right: 0;
  width: 90px;
  height: 90px;
  background: rgb(126, 201, 153);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.5);
  border-radius: 50%;
}

@keyframes pulse {
  100% {
    transform: scale(1.5);
    opacity: 0;
    background-color: rgb(126, 201, 153);
  }
}

.home__indicator-background::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: rgb(126, 201, 153);
  border-radius: 50%;
  opacity: 0.6;
  animation: pulse 2s ease-out infinite;
}



.home__indicator-text {
  color: white;
  position: absolute;
  bottom: 2%;
  right: 1%;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.home__video-control__wrapper {
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  z-index: 99999999;
}

.home__video-control {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.home__video-control__wrapper::after {
  content: "";
  position: absolute;
  height: 80%;
  opacity: 0.3;
  border-right: 1px solid #ffffff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.home__control-button {
  padding: 2rem;
}

.home__control-button i {
  color: white;
  font-size: 2.8rem;
}

.home__control-button img {
  height: 26px;
}

.video-desktop-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.video-desktop-wrapper iframe {
  width: 100%;
  height: 100vw;
}

@media screen and (max-width: 1650px) {
  .home__title {
    font-size: 32px;
  }

  .home__video-wrapper {
    width: calc(100vw - 148px);
    position: relative;
  }

  .home__slide-below {
    margin-right: 3.5rem;
  }


  .home__indicator-text {
    bottom: 2%;
    right: 1%;
    font-size: 14px;
  }

  .home__image-preview {
    margin-left: 3.75rem;

    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .home__control-button {
    padding: 1.5rem;
  }



  .home__control-button img {
    height: 22px;
  }
}

@media screen and (max-width: 1024px) {
  /*  -------- Home -------- */

  div.home__video-thumbnail iframe {
    width: 100vw;
  }

  div.section__content {
    height: 100%;
    width: 100%;
  }

  .home__descript--commercial>a,
  .home__descript--commercial>p {
    display: none;
  }


  .testIframe iframe {
    width: 100vw;
    height: 56.25vw;
    min-height: 100vh;
    min-width: 177.77vh;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  div.home__responsive-mobile-ver {
    display: flex;
    flex-direction: column;
  }

  .home__image-preview {
    display: flex;
    flex-direction: column;
    margin: 2.5rem 1.6rem;
  }

  h2.home__content {
    position: relative;
  }


  div.home__indicator-background {
    background: none;
    bottom: 0;
    left: 0;
    margin: auto;
  }

  div.home__indicator-background::before {
    display: none;
  }

  div.home__indicator-background img {
    transform: rotate(90deg);
  }

  .home__indicator-text {
    display: none;
  }

  /*  -------- Home -------- */

  /* Modal */
  div.modal {
    flex-direction: column;
  }

  div.modal__close-btn {
    top: 2.1rem;
    right: 3rem;
    width: 2rem;
    height: 2rem;
  }

  div.modal__content {
    width: 90vw;
    height: 40vh;
    margin: 5.6rem auto 0;
  }

  div.modal__content img {
    height: 40vh;
    width: 90vw;
    object-fit: cover;
  }

  div.modal__description {
    width: 90vw;
    margin: 3.2rem auto;
  }

  div.modal__description--heading {
    width: calc(30rem + 11.4666666667vw);

    margin-bottom: calc(1rem + 2.666666667vw);
  }

  div.modal__description--heading h3 {
    font-size: calc(1.5rem + 1.6vw);
    line-height: calc(2.34rem + 2.08vw);
    margin-bottom: calc(0.8rem + 2.666666667vw);
  }

  /* Modal */
}

@media screen and (max-width: 768px) {
  div.home__video-thumbnail iframe {
    width: 100vw;
  }


  div.home__responsive-mobile-ver {
    display: flex;
    flex-direction: column;
  }

  div.home__image-preview {
    display: flex;
    flex-direction: column;
    margin: 2rem 1.6rem;
  }

  .home__descript--commercial>a,
  .home__descript--commercial>p {
    display: none;
  }

  .home__control-button img {
    height: 18px;
  }
}