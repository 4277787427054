div.-shortad {
  width: 97.4%;
}

div.-fb {
  width: 94%;
}

div.-doc {
  width: 90.6%;
}

div.-fm {
  width: 93%;
}

div.-cy {
  width: 92.666667%;
}

div.-tch {
  width: 86%;
}

div.-mv {
  width: 83%;
}

.commercial__width100 {
  width: 100% !important;
  display: block !important;
  margin-top: 0px !important;
}

.commercial__width100 .-doc {
  width: 80.6%;
}

.commercial-images img {
  width: 100% !important;
  height: 250px !important;
}

.commercial__margin10 {
  margin-top: 10px !important;
  margin-bottom: 10px;
}

aside#side-nav {
  position: sticky !important;
}
